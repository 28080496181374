import { Atom, SHARED_VALIDATIONS } from ":mods";
import { AShowcase } from "../model";

export function createShowcaseInputs(stageID: string | number, details?: AShowcase) {
  const ID = Atom.Form.createInput(undefined, { disabled: true });
  const Stage = Atom.Form.createInput(stageID);
  const Name = Atom.Form.createInput(details?.name, { required: true });
  const Description = Atom.Form.createInput(details?.description, { required: true });
  const Image = Atom.Form.createInputFile(undefined, {
    required: false,
    validators: (v) => {
      if (!v) return undefined;
      else if (typeof v === "string") return undefined;
      const file = v[0];
      const is_safe_size = SHARED_VALIDATIONS.isFileSizeLessThan(file, 5, "MB");
      return !is_safe_size ? undefined : { [is_safe_size]: true };
    },
  });
  const Actions = Atom.Form.createFormActions(
    {
      ID,
      Stage,
      Name,
      Description,
      Image,
    },
    {
      defaultCase: "snake",
    }
  );

  // const values = Actions.getValuesUppercase({TotalMinutesQuiz: "kebab", TotalMinutesVideo: "snake"});
  return {
    Actions,
    ID,
    Stage,
    Name,
    Description,
    Image,
  };
}
